$(document).foundation();
$(() => {
  $.slidebars();

  if ($('.worked-with-image').length) {
    $('.worked-with-image').hover((e) => {
      const el = $(e.currentTarget);
      $(el).attr('src', $(el).attr('data-hover'));
    }, (e) => {
      const el = $(e.currentTarget);
      $(el).attr('src', $(el).attr('data-src'));
    });
  }

  $('#menu-main-navigation-menu .menu-item-has-children').hover((e) => {
    const el = $(e.currentTarget);
    $(el).find('.sub-menu').stop().fadeIn();
  }, (e) => {
    const el = $(e.currentTarget);
    $(el).find('.sub-menu').stop().fadeOut();
  });

  if ($('.form-select-position').length) {
    $('.form-select-position option').first().attr('disabled', 'disabled');
    const defaultPosition = $('<option>').text('Miscellaneous').val('miscellaneous');
    $('.form-select-position').append(defaultPosition);
    $('.positions-toggle').each((index, element) => {
      const posOption = $('<option>').text($(element).text()).val($(element).text());
      $('.form-select-position').append(posOption);
    });
  }

  $('#menu-slidebars-menu > li.menu-item-has-children > a').click((e) => {
    e.preventDefault();
    const mobileMenuItem = e.target;
    if ($(mobileMenuItem).hasClass('active')) {
      $(mobileMenuItem).removeClass('active');
      $(mobileMenuItem).next().stop().slideUp();
    } else {
      $(mobileMenuItem).addClass('active');
      $(mobileMenuItem).next().stop().slideDown();
    }
  });

  $('.testimonials-carousel').owlCarousel({
    loop: true,
    nav: true,
    navText: [],
    items: 1
  });

  /*  
  $('.mobile-images-carousel').owlCarousel({
    loop: true,
    items: 1,
    autoHeight: true
  });
  */

  let lockAnimation = false;
  let animationTimer;

  $('.overlap-images-1').click(() => {
    lockAnimation = true;
  });

  $('[data-fancybox]').fancybox({
    afterClose: (instance) => {
      if (lockAnimation) {
        const triggerParent = instance.$trigger.parent();
        if ($(triggerParent).hasClass('overlap-left')) {
          $(triggerParent).find('.overlap-images-2')
            .transition({ x: -350, duration: 200 })
            .transition({ 'zIndex': '100', duration: 100 })
            .transition({ x: 0, duration: 200 });
        } else {
          $(triggerParent).find('.overlap-images-2')
            .transition({ x: 350, duration: 200 })
            .transition({ 'zIndex': '100', duration: 100 })
            .transition({ x: 0, duration: 200 });
        }
        lockAnimation = false;
      }
    }
  });

  $('.overlap-left .overlap-images-1').hover((e) => {
    const that = e.target;
    animationTimer = setTimeout(() => {
      $(that).parent().parent().find('.overlap-images-2')
        .transition({ x: -350, duration: 200 })
        .transition({ 'zIndex': '2', duration: 100 })
        .transition({ x: 0, duration: 200 });
    }, 500);
  }, (e) => {
    const that = e.target;
    clearTimeout(animationTimer);
    if ($(that).parent().parent().find('.overlap-images-2').css('zIndex') === '2') {
      if (!lockAnimation) {
        $(that).parent().parent().find('.overlap-images-2')
          .transition({ x: -350, duration: 200 })
          .transition({ 'zIndex': '100', duration: 100 })
          .transition({ x: 0, duration: 200 });
      }
    }
  });

  $('.overlap-right .overlap-images-1').hover((e) => {
    const that = e.target;
    animationTimer = setTimeout(() => {
      $(that).parent().parent().find('.overlap-images-2')
        .transition({ x: 350, duration: 200 })
        .transition({ 'zIndex': '2', duration: 100 })
        .transition({ x: 0, duration: 200 });
    }, 500);
  }, (e) => {
    const that = e.target;
    clearTimeout(animationTimer);
    if ($(that).parent().parent().find('.overlap-images-2').css('zIndex') === '2') {
      if (!lockAnimation) {
        $(that).parent().parent().find('.overlap-images-2')
          .transition({ x: 350, duration: 200 })
          .transition({ 'zIndex': '100', duration: 100 })
          .transition({ x: 0, duration: 200 });
      }
    }
  });

});

/* global AOS */
$(window).on('load', () => {
  AOS.init();
});

let scrollDirection = 'down';
let previousScrollTop = 0;

function isInViewport(el) {
  const elementTop = el.offset().top;
  const elementBottom = elementTop + el.outerHeight();

  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

let isHeaderSticky = false;
if ($(window).scrollTop() > 20) {
  $('.header-wrapper').addClass('sticky');
  isHeaderSticky = true;
}
$(window).on('scroll', () => {
  if ($(window).scrollTop() > 20 && !isHeaderSticky) {
    $('.header-wrapper').addClass('sticky');
    isHeaderSticky = true;
  }
  if ($(window).scrollTop() <= 20 && isHeaderSticky) {
    $('.header-wrapper').removeClass('sticky');
    isHeaderSticky = false;
  }
});

if ($('.tl-content-divider.animated-mountains').length) {
  $(window).on('scroll', () => {

    if (previousScrollTop < $(window).scrollTop()) {
      // scrolling down
      scrollDirection = 'down';
    } else {
      // scrolling up
      scrollDirection = 'up';
    }
    previousScrollTop = $(window).scrollTop();

    if (isInViewport($('.mountain3'))) {
      // scroll mountains
      if (scrollDirection === 'down') {
        $('.mountain1').transition({ x: '+=12', easing: 'linear' });
        $('.mountain2').transition({ x: '+=3', easing: 'linear' });
        $('.mountain3').transition({ x: '+=6', easing: 'linear' });
        $('.mountain4').transition({ x: '+=6', easing: 'linear' });
        $('.mountain5').transition({ x: '+=2', easing: 'linear' });
        $('.mountain6').transition({ x: '+=12', easing: 'linear' });
      } else {
        $('.mountain1').transition({ x: '-=12', easing: 'linear' });
        $('.mountain2').transition({ x: '-=3', easing: 'linear' });
        $('.mountain3').transition({ x: '-=6', easing: 'linear' });
        $('.mountain4').transition({ x: '-=6', easing: 'linear' });
        $('.mountain5').transition({ x: '-=2', easing: 'linear' });
        if (parseInt($('.mountain6').css('transform').split(',')[4], 10) > 12) {
          $('.mountain6').transition({ x: '-=12', easing: 'linear' });
        }
      }    
    }
  });
}